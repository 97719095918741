import React from 'react';
import { BrowserRouter, Route, Routes, useLocation, useParams } from "react-router-dom";
import GalleryApp from '../containers/GalleryApp';
import { reducers } from '../store/configureShopStore';
import withReducers from '../store/withReducers';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';

const AppRoute = React.memo(({ show }) => {
  const params = useParams();
  const location = useLocation();

  if (show) {
    return <GalleryApp params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const GalleryRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path="/collection/:order_id"
        element={<AppRoute show />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default withReducers(GalleryRouter, reducers);
