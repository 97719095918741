import React, { Component } from 'react';
import { connect } from 'react-redux';

import { window } from '../../global';
import { getCompanyData } from '../../selectors';
import { getImageSrc } from '../../utils';

import { createAddToQuotePopup, createCartSummaryPopup, createCopyToProjectPopup } from '../../actions/popup';
import { createAddTemp, createDeleteTemp, createAddManyTemp, createDeleteManyTemp } from '../../actions/temp';

import { Small, SmallOnly, Medium } from '../ScreenSize';
import Header from './Header';
import Filter from './Filter';
import Tile, { DEFAULT_TILE_HEIGHT, DEFAULT_TILE_PADDING } from './Tile';
import FilmStrip from './FilmStrip';
import Footer from './Footer';
import ItemInfo from './ItemInfo';
import ItemComments from './ItemComments';

import Img from '../Img';

const screenChangeEvents = [
  'fullscreenchange',
  'webkitfullscreenchange',
  'mozfullscreenchange',
  'msfullscreenchange'
];

const SCROLLBAR_WIDTH = 25;

class Gallery extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      selected: props.selected,
      page: 'slide-show',
      extraImageId: {},
      playing: false,
      fullscreen: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      galleryWidth: window.innerWidth - 2 * DEFAULT_TILE_PADDING,
      itemSizes: props.order.items.filter(i => 'OPTION' === i.parent_type).reduce((s, i) => {
        s[i.item_id] = { width: DEFAULT_TILE_HEIGHT, height: DEFAULT_TILE_HEIGHT };
        return s;
      }, {})
    };

    this.playId = null;
    this.fullscreenRoot = null;

    this.onFilter = this.onFilter.bind(this);
    this.onSelectItem = this.onSelectItem.bind(this);
    this.onSelectNextItem = this.onSelectNextItem.bind(this);
    this.onSelectPreviousItem = this.onSelectPreviousItem.bind(this);
    this.onSelectPage = this.onSelectPage.bind(this);
    this.onSelectImage = this.onSelectImage.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.requestFullscreen = this.requestFullscreen.bind(this);
    this.exitFullscreen = this.exitFullscreen.bind(this);
    this.handleScreenchange = this.handleScreenchange.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    screenChangeEvents.forEach(e => {
      document.addEventListener(e, this.handleScreenchange);
    });
  }

  componentDidMount() {
    if (this.gallery) {
      this.setState({ galleryWidth: this.gallery.clientWidth });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    screenChangeEvents.forEach(e => {
      document.removeEventListener(e, this.handleScreenchange);
    });
    if (this.playId) {
      clearInterval(this.playId);
      this.playId = null;
    }
  }

  onFilter(filter) {
    this.setState({ filter });
  }

  onSelectItem(selected) {
    this.setState({ selected, page: 'slide-show' });
  }

  onSelectNextItem() {
    const { order: { items }, wrapScrolling = true } = this.props;
    const { selected } = this.state;

    const option_items = items.filter(i => 'OPTION' === i.parent_type);
    const item_index = option_items.map(i => i.item_id).indexOf(selected);

    if (item_index > option_items.length - 2) {
      if (wrapScrolling) {
        this.setState({ selected: option_items[0].item_id });
      }
    } else {
      this.setState({ selected: option_items[item_index + 1].item_id });
    }
  }

  onSelectPreviousItem() {
    const { order: { items }, wrapScrolling = true } = this.props;
    const { selected } = this.state;

    const option_items = items.filter(i => 'OPTION' === i.parent_type);
    const item_index = option_items.map(i => i.item_id).indexOf(selected);

    if (item_index < 1) {
      if (wrapScrolling) {
        this.setState({ selected: option_items[option_items.length - 1].item_id });
      }
    } else {
      this.setState({ selected: option_items[item_index - 1].item_id });
    }
  }

  onSelectPage(page) {
    this.setState({ page });
  }

  onSelectImage(image) {
    this.setState((state) => ({ extraImageId: { ...state.extraImageId, [state.selected]: image } }));
  }

  play() {
    const { slideDuration = 3000 } = this.props;

    if (this.playId) {
      clearInterval(this.playId);
      this.playId = null;
    }
    this.setState({ playing: true }, () => {
      this.playId = setInterval(this.onSelectNextItem, slideDuration);
    });
  }

  pause() {
    if (this.playId) {
      clearInterval(this.playId);
      this.playId = null;
    }
    this.setState({ playing: false });
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    if (this.gallery) {
      this.setState({ galleryWidth: this.gallery.clientWidth });
    }
  }

  handleScreenchange() {
    const fullscreen = (document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement);
    this.setState({ fullscreen });
  }

  requestFullscreen() {
    const requestFullscreen = this.fullscreenRoot && (this.fullscreenRoot.requestFullscreen || this.fullscreenRoot.webkitRequestFullscreen || this.fullscreenRoot.mozRequestFullScreen || this.fullscreenRoot.msRequestFullscreen);

    this.setState({ fullscreen: true}, requestFullscreen ? requestFullscreen.bind(this.fullscreenRoot) : null);
  }

  exitFullscreen() {
    const exitFullscreen = document.exitFullscreen || document.webkitExitFullscreen || document.mozExitFullScreen || document.msExitFullscreen;
    this.setState({ fullscreen: false}, exitFullscreen ? exitFullscreen.bind(document) : null);
  }

  getDisplayItems(item_id) {
    const items = this.props.order.items;

    if (!item_id) {
      return items.filter(i => 'OPTION' === i.parent_type);
    }
    const startIndex = items.map(i => i.item_id).indexOf(item_id);
    if (-1 === startIndex) {
      return [];
    }
    const endIndex = items.slice(startIndex + 1).map(i => i.parent_type).indexOf('SEPARATOR');
    if (-1 === endIndex) {
      return items.slice(startIndex).filter(i => 'OPTION' === i.parent_type);
    }
    return items.slice(startIndex, startIndex + endIndex + 1).filter(i => 'OPTION' === i.parent_type);
  }

  renderDetailMenu(item) {
    const { showComments = true } = this.props;
    const { fullscreen, page } = this.state;

    if (fullscreen) {
      return null;
    }

    const activeStyle = {
      background: 'white',
      color: 'black',
      fontWeight: 'normal'
    };

    const inactiveStyle = {
      background: '#edf2f5',
      color: '#5ca3b6',
      fontWeight: 'normal'
    };

    const commentCount = Object.values(item.comments).length;

    return (
      <div className="row small-12 columns" style={{ padding: 0, marginLeft: 0, marginRight: 0, width: '100%', height: '40px' }}>
        <Medium>
          <ul className="menu" style={{ cursor: 'pointer', marginBottom: '0.5rem' }}>
            <li className="menu-text" onClick={e => this.onSelectItem(null)}><i className="fi-x"></i></li>
          </ul>
        </Medium>
        <SmallOnly>
          <ul className="menu" style={{ display: 'table', width: '100%', cursor: 'pointer', marginBottom: '0.5rem' }}>
            <li className="menu-text" style={{ background: '#bf3f69', color: 'white' }} onClick={e => this.onSelectItem(null)}><i className="fi-x"></i></li>
            <li className="menu-text" style={'slide-show' === page ? activeStyle : inactiveStyle} onClick={ e => this.onSelectPage('slide-show')}>gallery</li>
            <li className="menu-text" style={'info' === page ? activeStyle : inactiveStyle} onClick={ e => this.onSelectPage('info')}>info</li>
            {showComments ? <li className="menu-text" style={'comment' === page ? activeStyle : inactiveStyle} onClick={ e => this.onSelectPage('comment')}>comment ({commentCount})</li> : null}
          </ul>
        </SmallOnly>
      </div>
    );
  }

  renderDetail() {
    const { order: { items } } = this.props;
    const { fullscreen, selected } = this.state;

    const item = items.filter(i => i.item_id === selected)[0];

    const style = fullscreen ? {
      position: 'relative',
      width: '75%'
    } : {
      position: 'relative',
      top: '130px',
      background: 'white',
      minHeight: 'calc(100vh - 130px)'
    };

    return (
      <div ref={ref => { this.fullscreenRoot = ref; }} style={style}>
        {this.renderDetailMenu(item)}
        <div className="row collapse" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%' }}>
          {this.renderSlideShow(item)}
          {this.renderMedium(item)}
          {this.renderSmall(item)}
        </div>
      </div>
    );
  }

  renderSmall(item) {
    const { fullscreen, page } = this.state;

    return (
      <SmallOnly>
        <div>
          {'info' === page ? this.renderInfo(item) : null}
          {'info' === page ? this.renderInfoButtons(item, { paddingLeft: '1rem', paddingRight: '1rem' }) : null}
          {'comment' === page ? this.renderComments(item) : null}
        </div>
      </SmallOnly>
    );
  }

  renderInfoButtons(item, style) {
    const { showComments = true } = this.props;
    return (
      <div className="row collapse" style={style}>
        <div className={`small-12 ${showComments ? 'medium-6' : ''} columns`}>
          {this.renderAddItemButton(item)}
        </div>
        { showComments ?
          <Medium>
            <div className="medium-6 columns">
              {this.renderCommentButton(item)}
            </div>
          </Medium> :
        null}
      </div>
    );
  }

  renderAddItemButton(item) {
    const { onAddItem, onRemoveItem, showComments = true, showItemCount = true } = this.props;

    const countStyle = {
      background: 'white',
      color: '#2d424c',
      padding: '0.5rem 0.75rem',
      borderRadius: '1rem',
      marginLeft: '0.2rem',
      fontWeight: 'bold'
    };

    const onClick = e => {
      e.stopPropagation();
      if (showItemCount || !item.quantity) {
        onAddItem(item.item_id, item.parent_type);
      } else {
        onRemoveItem(item.item_id);
      }
    };

    return (
      <button className="button expanded" style={{ height: '3rem', background: '#c8295e' }} onClick={onClick}>
        {item.quantity ?
          <span>Added <span style={countStyle}>{showItemCount ? item.quantity : <i className="fi-check"></i>}</span></span> :
          <span>Add</span>}
      </button>
    );
  }

  renderCommentButton(item) {
    const { showComments = true } = this.props;

    if (!showComments) {
      return null;
    }

    const commentCount = Object.values(item.comments).length;

    const countStyle = {
      background: 'white',
      color: '#2d424c',
      padding: '0.5rem 0.75rem',
      borderRadius: '1rem',
      marginLeft: '0.2rem',
      fontWeight: 'bold'
    };

    return (
      <button
        className="button expanded"
        style={{ height: '3rem', marginLeft: '0.5rem' }}
        onClick={e => this.onSelectPage('comment')}
      >
        Comments <span style={countStyle}>{commentCount}</span>
      </button>
    );
  }

  renderMedium(item) {
    const { showComments = true } = this.props;
    const { fullscreen, page } = this.state;

    if (fullscreen) {
      return null;
    }

    const style = {
      height: 'calc(100vh - 170px)',
      borderLeft: '1px solid black',
      padding: '1rem 0.25rem 1rem 1rem',
      overflow: 'hidden'
    };

    return (
      <Medium>
        <div className="medium-5 columns" style={style}>
          {'slide-show' === page || 'info' === page || !showComments ?
            <div className="row collapse" style={{ overflowY: 'auto', height: 'calc(100vh - 170px - 6rem)', marginBottom: '1rem' }}>
              {this.renderInfo(item)}
            </div> :
            <div className="row small-12 columns" style={{ padding: 0 }}>
              <button className="button secondary" onClick={e => this.onSelectPage('info')}>&laquo;&nbsp; Product Description</button>
            </div>}
          {'slide-show' === page || 'info' === page || !showComments ?
            this.renderInfoButtons(item, { paddingRight: '1rem' }) :
            <div className="row collapse" style={{ height: 'calc(100vh - 170px)' }}>
              {this.renderComments(item, { height: 'calc(100vh - 220px - 13rem)', overflowY: 'auto' })}
            </div>}
        </div>
      </Medium>
    );
  }

  renderSlideShow(item) {
    const { extraImageId, fullscreen, page, playing, selected } = this.state;

    const displayItems = this.getDisplayItems();
    const anyExtraImages = displayItems.reduce((t, i) => t || i.images.length > 1, false);
    const extraImages = item.images.map(f => ({
      item_id: f.file_id,
      file: f
    }));
    const itemIndex = displayItems.map(i => i.item_id).indexOf(selected);
    const extraImageIndex = extraImages.map(i => i.item_id).indexOf(extraImageId[selected]);

    const mainImages = displayItems.map(i => ({
      item_id: i.item_id,
      file: i.images.filter(f => f.file_id === extraImageId[i.item_id])[0] || i.images[0]
    }));

    const imageStyle = {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      maxWidth: '100%',
      maxHeight: '100%'
    };
    const Component = 'slide-show' === page ? Small : Medium;

    const imageHeight = `calc(100vh - 2rem - 100px - 170px${anyExtraImages ? ' - 2rem - 75px' : ''})`;
    return (
      <Component>
        <div className={`small-12 ${fullscreen ? '' : 'medium-7'} columns`}>
          {extraImages.length > 1 ?
            <FilmStrip items={extraImages} index={extraImageIndex < 0 ? 0 : extraImageIndex} size={75} onSelect={this.onSelectImage} /> :
            (anyExtraImages ?
            <div style={{ height: 'calc(2rem + 75px)' }}>&nbsp;</div> :
            null)}
          <div className="row small-12 columns collapse" style={{ position: 'relative', height: imageHeight, overflow: 'hidden', background: 'white', textAlign: 'center', padding: 0 }}>
            <div style={{ display: 'flex', height: '100%', width: (displayItems.length * 100) + '%', transform: 'translateX(-' + (itemIndex / displayItems.length * 100) + '%)', transition: 'transform 1s' }}>
              {mainImages.map((i, index) =>
                <div key={i.item_id} style={{ width: '100%', height: '100%', flex: 1 }}>
                  <Img style={imageStyle} src={getImageSrc(i.file, 'large')} />
                </div>)}
            </div>
            <a className="image-gallery-left-nav" onClick={this.onSelectPreviousItem}></a>
            <a className={`image-gallery-play-button ${playing ? 'active' : ''}`} onClick={playing ? this.pause : this.play}></a>
            <a className="image-gallery-right-nav" onClick={this.onSelectNextItem}></a>
            <Medium component="a" className={`image-gallery-fullscreen-button ${fullscreen ? 'active' : ''}`} onClick={fullscreen ? this.exitFullscreen : this.requestFullscreen}></Medium>
          </div>
          <FilmStrip items={displayItems.map(i => ({ ...i, file: i.images[0]}))} index={itemIndex} size={100} onSelect={this.onSelectItem} />
        </div>
      </Component>
    );
  }

  renderInfo(item, style) {
    const { order: { currency_symbol, order_type, supplier_id }, default_margin, onAddItem, onRemoveItem, showItemCount = true, showComments = true } = this.props;
    return (
      <ItemInfo item={item} showProductWarnings={'COLLECTION' === order_type} currency_symbol={currency_symbol} default_margin={default_margin} supplier_id={supplier_id} style={style} />
    );
  }

  renderComments(item, style) {
    const { showComments = true } = this.props;

    if (!showComments) {
      return null;
    }

    return (
      <ItemComments item={item} style={style} />
    );
  }

  getWidths(items, fullWidth, fullHeight) {
    const { itemSizes } = this.state;
    const maxRows = Math.floor((fullHeight - 130 - 102) / DEFAULT_TILE_HEIGHT);
    return items.reduce((rows, item) => {
      const lastRow = rows[rows.length - 1];
      const rowWidth = lastRow.reduce((t, w) => t + w, 0);
      const scale = DEFAULT_TILE_HEIGHT / itemSizes[item.item_id].height;
      const itemWidth = itemSizes[item.item_id].width * scale + DEFAULT_TILE_PADDING * 2;
      if (rowWidth + itemWidth > fullWidth + itemWidth / 2) {
        return rows.concat([[ itemWidth ]]);
      } else {
        return rows.slice(0, -1).concat([lastRow.concat(itemWidth)]);
      }
    }, [[]]).reduce((result, row, index, rows) => {
      const rowWidth = row.reduce((t, w) => t + w, 0);
      if (rowWidth < fullWidth && index === rows.length - 1) {
        return result.concat(row);
      }
      const allButLast = row.slice(0, -1).map(w => Math.floor(w / rowWidth * fullWidth));
      return result.concat(allButLast.concat(fullWidth - allButLast.reduce((t, w) => t + w, 0)));
    }, []);

  }

  renderGallery() {
    const { order: { items, currency_symbol }, onAddItem, onRemoveItem, showItemCount = true, showComments = true } = this.props;
    const { filter, galleryWidth, windowWidth, windowHeight } = this.state;

    const display_items = this.getDisplayItems(filter);

    const onImageLoad = item_id => e => {
      const size = { width: e.target.naturalWidth, height: e.target.naturalHeight };
      this.setState(state => ({ itemSizes: { ...state.itemSizes, [item_id]: size } }));
    };

    const widths = this.getWidths(display_items, galleryWidth, windowHeight);
    return (
      <div style={{ position: 'relative', top: '130px' }}>
        <Filter filter={filter} items={items} onFilter={this.onFilter} />
        <div ref={ref => this.gallery = ref} className="row" style={{ marginLeft: DEFAULT_TILE_PADDING, marginRight: DEFAULT_TILE_PADDING, maxWidth: '100%' }} >
          {display_items.map((item, index) => <Tile
            key={item.item_id}
            item={item}
            currency_symbol={currency_symbol}
            onClick={() => this.onSelectItem(item.item_id)}
            onAddItem={onAddItem}
            onRemoveItem={onRemoveItem}
            showItemCount={showItemCount}
            showComments={showComments}
            width={widths[index]}
            height={DEFAULT_TILE_HEIGHT}
            padding={DEFAULT_TILE_PADDING}
            onImageLoad={onImageLoad(item.item_id)}
          />)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }

  renderExpired() {
    const { order: { order_type, sales_rep, company: { company_address } } } = this.props;
    return (
      <div style={{ position: 'relative', top: '130px' }}>
        <div className="row" style={{ marginLeft: DEFAULT_TILE_PADDING * 2, marginRight: DEFAULT_TILE_PADDING * 2, marginTop: DEFAULT_TILE_PADDING * 2, padding: '1rem', background: 'white', maxWidth: '100%' }}>
          <h3>This {order_type.toLowerCase()} has expired</h3>
          <p>Unfortunately, this {order_type.toLowerCase()} has expired and is no longer available.</p>
        </div>
        <Footer sales_rep={sales_rep} address={company_address} style={{ padding: 2 * DEFAULT_TILE_PADDING }} />
      </div>
    );
  }

  renderHeader() {
    const { order: { items, job_name, company, expired }, onCreateCartSummaryPopup, onSelectAll, onUnselectAll, showItemCount = true } = this.props;

    const totalCartItems = items.reduce((t, i) => t + (showItemCount ? i.quantity : (i.quantity ? 1 : 0)), 0);

    return (
      <Header avatar={company.company_avatar} job_name={job_name} company_name={company.company_name} locked={expired} totalCartItems={totalCartItems} totalItems={items.reduce((t, i) => t + ('OPTION' === i.parent_type ? 1 : 0), 0)} onCreateCartSummaryPopup={onCreateCartSummaryPopup} destination={showItemCount ? 'Order' : 'Copy to Shop / Project'} icon={showItemCount ? 'fi-shopping-cart' : 'fi-folder-add'} onSelectAll={onSelectAll} onUnselectAll={onUnselectAll} />
    );
  }

  renderFooter() {
    const { order: { sales_rep, company: { company_address } } } = this.props;
    return (
      <Footer sales_rep={sales_rep} address={company_address} style={{ padding: 2 * DEFAULT_TILE_PADDING }} />
    );
  }

  render() {
    const { order: { expired } } = this.props;
    const { selected } = this.state;

    return (
      <div style={{ background: '#f2f2f2' }}>
        {this.renderHeader()}
        {expired ? this.renderExpired() : (selected ? this.renderDetail() : this.renderGallery())}
      </div>
    );
  }
}

const mapStateToProps = state =>({
  default_margin: getCompanyData(state).default_margin || 40
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const { showItemCount = true } = ownProps;
  const onAddItem = ownProps.onAddItem || (showItemCount ?
    (item_id, item_type) => dispatch(createAddToQuotePopup(item_id, item_type)) :
    (item_id, item_type) => {
      const item = ownProps.order.items.filter(i => i.item_id === item_id)[0];
      return dispatch(createAddTemp(item_id, { item_id, item_name: item.item_name, total_quantity: 1 }));
    });
  const onCreateCartSummaryPopup = showItemCount ?
    () => dispatch(createCartSummaryPopup()) :
    () => dispatch(createCopyToProjectPopup(ownProps.order.order_id, ownProps.order.items.filter(i => i.quantity > 0)));
  const onSelectAll = !showItemCount ?
    () => dispatch(createAddManyTemp(ownProps.order.items.filter(i => 'OPTION' === i.parent_type).reduce((o, i) => {
      o[i.item_id] = { item_id: i.item_id, item_name: i.item_name, total_quantity: 1 };
      return o;
    }, {}))) : null;
  const onUnselectAll = !showItemCount ? () => dispatch(createDeleteManyTemp(ownProps.order.items.map(i => i.item_id))) : null;
  return {
    onAddItem,
    onRemoveItem: item_id => dispatch(createDeleteTemp(item_id)),
    onCreateCartSummaryPopup,
    onSelectAll,
    onUnselectAll
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
