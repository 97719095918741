import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const { sales_rep, address, style } = this.props;

    const textStyle = {
      marginBottom: 0
    };

    return (
      <div className="row" style={{ marginLeft: 0, marginRight: 0, maxWidth: '100%', padding: '1rem', ...style }}>
        <div className={address && address.address_line_1 ? 'small-12 medium-6 columns' : 'small-12 columns'} style={{ padding: '1rem', background: 'white' }}>
          <p style={textStyle}><b style={{ color: '#2d424c' }}>{sales_rep.contact_first_name} {sales_rep.contact_last_name}</b>{sales_rep.contact_position ? <span> - {sales_rep.contact_position}</span> : null}</p>
          <p style={textStyle}><a href={`mailto:${sales_rep.contact_email}`} style={{ color: '#5ca3b6' }}>{sales_rep.contact_email}</a></p>
        </div>
        {address && address.address_line_1 ?
        <div className="small-12 medium-6 columns" style={{ padding: '1rem', background: 'white' }}>
          <p style={textStyle}>{address.address_line_1}</p>
          {address.address_line_2 ? <p style={textStyle}>{address.address_line_1}</p> : null}
          {address.address_line_3 ? <p style={textStyle}>{address.address_line_3}</p> : null}
          {address.address_line_4 ? <p style={textStyle}>{address.address_line_4}</p> : null}
          <p style={textStyle}>{address.address_city}, {address.address_state} {address.address_postal} { address.address_country}</p>
        </div> : null}
      </div>
    );
  }
}

export default Footer;
