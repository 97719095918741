import React, { Component } from 'react';

import Img from '../Img';

class Header extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { avatar, job_name, locked, company_name, style, totalCartItems, totalItems, onCreateCartSummaryPopup, onSelectAll, onUnselectAll, icon = 'fi-shopping-cart', destination = 'Order' } = this.props;

    const cartStyle = {
      fontWeight: 'bold',
      display: 'inline',
      color: '#fff',
      textAlign: 'right',
      width: 'auto',
      borderRadius: '2rem',
      cursor: 'pointer',
      padding: '0.75rem 1rem',
      background: totalCartItems > 0 ? '#c8295e' : 'rgba(0,0,0,.2)'
    };

    const cartItemsStyle = {
      display: 'inline',
      marginLeft: '1rem',
      marginRight: '-0.75rem',
      padding: '0.5rem 0.75rem',
      fontWeight: 'bold',
      borderRadius: '1rem',
      background: totalCartItems > 0 ? '#fff' : 'rgba(0,0,0,.5)',
      color: totalCartItems > 0 ? '#2d424c' : 'rgba(255,255,255,.6)'
    };

    const headerStyle = {
      background: '#2d424c',
      padding: '1rem',
      height: '130px',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      zIndex: 2,
      marginLeft: 0,
      marginRight: 0,
      maxWidth: '100%',
      ...style
    };

    const avatarStyle = {
      padding: 0,
      borderRadius: '0.25rem',
      width: '100px',
      overflow: 'hidden',
      float: 'left'
    };

    const short_job_name = job_name.length > 50 ? job_name.substr(0, 50) + '...' : job_name;
    return (
      <div className="row" style={headerStyle}>
        <div style={avatarStyle}>
          <Img src={avatar} />
        </div>
        <div className="hide-for-small-only" style={{ float: 'left', padding: '0 1rem', maxWidth: '80%' }}>
          <h3 style={{ color: '#fff', whiteSpace: 'nowrap' }} title={job_name}>{short_job_name}</h3>
          <p style={{ color: 'rgba(255,255,255,0.5)' }}>by {company_name}</p>
        </div>
        {!locked && onSelectAll && onUnselectAll &&
          <div style={{ position: 'absolute', top: '30px', right: '10px' }} onClick={totalCartItems < totalItems ? onSelectAll : onUnselectAll}>
             <div style={{ ...cartStyle, background: totalCartItems < totalItems ? '#c8295e' : 'rgba(0,0,0,.2)' }}>
               <span>{totalCartItems < totalItems ? 'Add' : 'Remove'} All</span>
               <span style={{ ...cartItemsStyle, color: totalCartItems < totalItems ? '#2d424c' : 'rgba(255,255,255,.6)', background: totalCartItems < totalItems ? '#fff' : 'rgba(0,0,0,.5)' }}>{totalCartItems < totalItems ? <span>+</span> : <span>&ndash;</span>}</span>
             </div>
          </div>}
        {!locked &&
          <div style={{ position: 'absolute', top: '80px', right: '10px' }} onClick={totalCartItems ? onCreateCartSummaryPopup : null}>
             <div style={cartStyle}>
               <span className="hide-for-small-only">{destination}</span>
               <span className="show-for-small-only"><i className={icon} style={{ fontSize: '25px'}}></i></span>
               <span style={cartItemsStyle}>{totalCartItems}</span>
             </div>
          </div>}
      </div>
    );
  }
}

export default Header;
